import { ArrowRight } from '@phosphor-icons/react';
import Link, { LinkProps as Props } from './Link';

const ArrowLink = ({ children, ...props }: Props) => (
  <Link
    variant="iconLink"
    color="highlightColor"
    textStyle="sm"
    fontWeight="600"
    {...props}
  >
    {children}
    <ArrowRight size="16" />
  </Link>
);

export default ArrowLink;
